
import notice from "@/api/web/home/notice";

// 引入样式
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'
export default {
  components: {
    quillEditor
  },
  data() {
    return {
        noticeData:"",
        id:0,
        lang: "",
        editorOption: {
          placeholder: '',
          theme: 'snow', // or 'bubble'
          modules: {
            toolbar: {
              container: [] // 工具栏
  
            }
          }
        },

    };
  },
  watch: {
    
  },
  created() {
     this.getNoticeDetail()
     this.getLang()
     setTimeout(() => {
      this.$refs.QuillEditor.quill.enable(false)
     }, 500);
  },

  methods: {
   // 获取公告栏
   getNoticeDetail(){
      var query = this.$route.query;
      this.id = query.id;
      notice.getDetail(this.id).then((res) => {
        this.noticeData=res.data.data?res.data.data:"";
      })
   },
   // 获取当前的语言环境
   getLang() {
      // if (this.$i18n.locale == "zh-CN") {
      // 	this.lang = this.$i18n.locale
      // } else {
      // }
      this.lang = this.$i18n.locale;
   },

    // 设置富文本编辑器不可编辑
  focus(event) {
    event.enable(false)
  },

  },
};
